import store from './store.js';
import axios from 'axios';
import * as qs from 'qs'

const { VUE_APP_API_URL } = process.env;

const getAuthHeaders = (_isUrlencoded) => {
  return {
    accept: "application/json",
    "Content-Type": _isUrlencoded ? "application/x-www-form-urlencoded" : "application/json",
    "Authorization": `Bearer ${store.user.access_token}`
  }
}

const login = async (_user, _pass) => {
  try {
    var apiLoginData = qs.stringify({
      grant_type: "password",
      username: _user,
      password: _pass,
      client_secret: "secret_for_randomstate_customer",
      usertype: "Customer",
      client_id: "randomstate_customer",
      scope: "randomstate_customer offline_access"
    });

    var config = {
      method: "post",
      url: VUE_APP_API_URL + "/connect/token",
      headers: {
          "Content-Type": "application/x-www-form-urlencoded"
      },
      data: apiLoginData
    };

    let response = await axios(config);

    store.user = {
      username: _user,
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
      balance: { total: null, money: null, bonus: null, goods: null }
    };
    store.page = 'loggedin';
    updateBalance();
    return true;
  } catch {
    return false;
  }
};

const transactions = async (pageNumber, pageSize) => {
  var config = {
    method: "GET",
    url: VUE_APP_API_URL + `/api/CustomerTransaction?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    headers: getAuthHeaders(true)
  };
  try{
    var response = await axios(config);
    store.user.transactions = response.data;
  } catch(err) {
    return false;
  }
};

const updateBalance = async () => {
  var config = {
    method: "GET",
    url: VUE_APP_API_URL + "/api/CustomerTransaction/CombinedBalance",
    headers: getAuthHeaders(true)
  };

  axios(config).then(response => {
    store.user.balance = {
      total: response.data.totalValue,
      money: response.data.moneyValue,
      goods: response.data.goodsValue,
      bonus: response.data.bonusValue
    };
    return true;
  }).catch(error => {
    console.log(error);
    return false;
  })

};

const getUnfinishedTickets = async (_gameCollectionId) => {
  try {
    var payload = {
      gameId: _gameCollectionId,
      channel: "",
      additionalData: "",
    };

    var config = {
      method: "POST",
      url: VUE_APP_API_URL + "/api/Game/GetUnfinishedTickets",
      headers: getAuthHeaders(false),
      data: payload
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};


const buyGameCollection = async (_gameCollectionId, _buyArguments) => {
  try {
    var payload = {
      gameId: _gameCollectionId,
      channel: "test",
      additionalData: "test",
    };
    if (_buyArguments) {
      payload.buyArguments = _buyArguments;
    }

    var config = {
      method: "PUT",
      url: VUE_APP_API_URL + "/api/Game",
      headers: getAuthHeaders(false),
      data: payload
    };

    let response = await axios(config)
    return response.data;
  } catch(error) {
    console.dir(error.response);
    if (error.response.data) {
      alert('Error: '+error.response.data);
    }
    return false;
  }

};

const finalizeGameTicket = async (_verb, gameType, _ticketId) => {
  try {
    var config = {
      method: _verb,
      url: VUE_APP_API_URL + "/api/Game/finalizeTicket?gameType=" + gameType +"&ticketId="+_ticketId,
      headers: getAuthHeaders(false)
    };

    let response = await axios(config);
    return response.data;
  } catch {
    return false;
  }
};

const finalizeGameWithCorrelationId = async (_correlationId) => {
  try {
    var config = {
      method: 'PUT',
      url: VUE_APP_API_URL + "/api/Game/finalizeGameCollection/"+_correlationId,
      headers: getAuthHeaders(false)
    };

    let res = await axios(config);
    return res.data;
  } catch(error) {
    return false;
  }
};



const getRaffleInfo = async (_raffleId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/Raffles/"+_raffleId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const getMyRaffleTicketsInRound = async (_raffleRoundId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/Raffles/"+_raffleRoundId+'/MyNumbers',
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};


const getRaffleRounds = async (_raffleId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/Raffles/"+_raffleId+'/Rounds',
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};


const getMyRaffleRoundResults = async (_raffleId, _roundId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/Raffles/"+_raffleId+'/MyResults/'+_roundId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};


const getJackpotTicket = async (_ticketId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/jackpot/Tickets/"+_ticketId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const getInstantTicket = async (_ticketId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/Instant/Tickets/"+_ticketId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const getWinLoseTicket = async (_ticketId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/winlose/Tickets/"+_ticketId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const getProbabilityTicket = async (_ticketId) => {
  try {
    var config = {
      method: "GET",
      url: VUE_APP_API_URL + "/api/probability/Tickets/"+_ticketId,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const pickProbabilityNumber = async (_ticketId, number) => {
  try {
    var config = {
      method: "POST",
      url: VUE_APP_API_URL + "/api/probability/Tickets/"+_ticketId+"/picks/"+number,
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

const cashOut = async (_ticketId) => {
  try {
    var config = {
      method: "POST",
      url: VUE_APP_API_URL + "/api/probability/Tickets/"+_ticketId+"/cashouts",
      headers: getAuthHeaders(false),
    };

    var res = await axios(config);
    return res.data;
  } catch(err) {
    return false;
  }
};

export {
  login,
  updateBalance,
  getUnfinishedTickets,
  buyGameCollection,
  finalizeGameTicket,
  finalizeGameWithCorrelationId,
  getRaffleInfo,
  getMyRaffleTicketsInRound,
  getRaffleRounds,
  getMyRaffleRoundResults,
  getJackpotTicket,
  getInstantTicket,
  transactions,
  getWinLoseTicket,
  getProbabilityTicket,
  pickProbabilityNumber,
  cashOut
}