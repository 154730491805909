<template>
  <div>
    <div v-if="lastTicketResult && formattedLayouts.length">
      <div class="flex-wrapper">
        <div v-for="(layout, layoutIndex) in formattedLayouts" :key="layoutIndex" class="grid-container">
          <div class="layout-label">Layout {{ layoutIndex + 1 }}</div>
          <div v-for="(row, rowIndex) in layout" :key="rowIndex" class="grid-row">
            <span v-for="(cell, cellIndex) in row" :key="cellIndex" class="grid-cell">
              {{ cell }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstantLayoutGenerator',
  props: {
    lastTicketResult: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentLayoutIndex: 0,
      showFaded: false,
      fadeTimeout: null,
    };
  },
  computed: {
    formattedLayouts() {
      const emojiMap = {
        'A': '🍎',
        'B': '🍌',
        'C': '🍒',
        'D': '🍇',
        'E': '🍉',
        'F': '🍓',
        'G': '🍍',
        'H': '🥝',
        'I': '🍋',
        'MINI': '⭐',
        'MEGA': '🏆',
        'TNT': '💣',
        'W': '🃏',
      };

      // Parse the layout string into multiple 5x5 grids
      const layoutString = this.lastTicketResult.layout;
      const grids = layoutString.split('|');
      const layouts = grids.map(grid => {
        const rows = Array(5).fill(null).map(() => Array(5).fill(''));
        grid.trim().split('\n').forEach(row => {
          const match = row.match(/\[(\d+),\s*(\d+),\s*(.*?)\]/);
          if (match) {
            const x = parseInt(match[1], 10);
            const y = parseInt(match[2], 10);
            const value = match[3];
            rows[x][y] = emojiMap[value] || value;
          }
        });
        return rows;
      });

      return layouts;
    },
    currentLayout() {
      return this.formattedLayouts[this.currentLayoutIndex];
    },
  },
  methods: {
    isFaded(cell) {
      const count = this.currentLayout.flat().filter(c => c === cell || c === '🃏').length;
      return count >= 5;
    },
    nextLayout() {
      this.showFaded = false;
      if (this.currentLayoutIndex < this.formattedLayouts.length - 1) {
        this.currentLayoutIndex++;
      } else {
        this.currentLayoutIndex = 0;
      }
      clearTimeout(this.fadeTimeout);
      this.fadeTimeout = setTimeout(() => {
        this.showFaded = true;
      }, 1000); 
    },
    startTransition() {
      this.nextLayout(); 
      setInterval(this.nextLayout, 4000); 
    },
  },
  mounted() {
    setTimeout(this.startTransition, 4000); 
  },
};
</script>

<style>
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}
.grid-container {
  margin-bottom: 20px;
}

.layout-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.grid-row {
  display: flex;
}

.grid-cell {
  width: 80px;
  height: 80px;
  display: flex;
  font-size: 75px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  margin: 2px;
}

.faded {
  transition: opacity 1s;
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>