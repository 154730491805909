<template>
  <div>
    <div v-if="isDataReady && formattedLayouts.length">
      <div class="grid-container">
        <div class="layout-label">Animated layout</div>
        <div class="grid" :style="{
          'grid-template-columns': `repeat(${cols}, 80px)`,
          'grid-template-rows': `repeat(${rows}, 80px)`
        }">
          <div v-for="cell in gameGrid" 
              :key="`${cell.row}-${cell.col}`"
              class="grid-cell"
              :id="`cell-${cell.row}-${cell.col}`"
              :style="{
                gridRow: cell.row + 1,
                gridColumn: cell.col + 1
              }">
            <div class="cell-content" :id="`content-${cell.row}-${cell.col}`">
              {{ cell.value }}
            </div>
          </div>
        </div>
        <br>
        <div v-if="formattedLayouts[0].length > 1" class="flex-buttons">
          <button v-if="!animationStarted" class="button" @click="startAnimation">Start</button>
          <button v-if="animationStarted" class="button" @click="replayAnimation">Replay</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'InstantLayoutAnimation',
  props: {
    lastTicketResult: {
      type: Object,
      required: true,
    },
    rows: {
      type: Number,
      default: 5
    },
    cols: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      currentLayoutIndex: 0,
      gameGrid: this.createGrid(),
      isDataReady: false,
      cascadeCount: 0,
      animationStarted: false,
      tntDetonated: false // Add flag to track TNT detonation
    };
  },
  computed: {
    // Update formattedLayouts computed
    formattedLayouts() {
      if (!this.lastTicketResult?.layout) return [];
      
      const emojiMap = {
        'A': '🍎', 'B': '🍌', 'C': '🍒', 'D': '🍇',
        'E': '🍉', 'F': '🍓', 'G': '🍍', 'H': '🥝',
        'I': '🍋', 'MINI': '⭐', 'MEGA': '🏆',
        'TNT': '💣', 'W': '🃏'
      };

      return this.lastTicketResult.layout.split('|').map(grid => {
        const rows = Array(this.rows).fill(null).map(() => Array(this.cols).fill(''));
        grid.trim().split('\n').forEach(row => {
          const match = row.match(/\[(\d+),\s*(\d+),\s*(.*?)\]/);
          if (match) {
            const rowIdx = parseInt(match[1], 10);
            const colIdx = parseInt(match[2], 10);
            if (rowIdx < this.rows && colIdx < this.cols) {
              rows[rowIdx][colIdx] = emojiMap[match[3]] || match[3];
            }
          }
        });
        return rows;
      });
    },
  },

  methods: {
    createGrid() {
      const grid = [];
      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          grid.push({
            row,
            col,
            value: ''
          });
        }
      }
      return grid;
    },

    async removeMatches(matches) {
      return new Promise(resolve => {
        matches.forEach(pos => {
          const [row, col] = pos.split('-');
          const cell = this.gameGrid.find(c => c.row === parseInt(row) && c.col === parseInt(col));
          const element = document.querySelector(`#content-${row}-${col}`);
          
          if (element && cell) {
            const tl = gsap.timeline();
            
            // Highlight animation sequence
            tl.to(element, {
              scale: 1.2,
              duration: 0.2
            })
            .to(element, {
              scale: 0.8,
              duration: 0.2
            })
            .to(element, {
              scale: 1.2,
              duration: 0.2
            })
            .to(element, {
              scale: 1,
              duration: 0.2
            })
            // Removal animation
            .to(element, {
              scale: 0,
              opacity: 0,
              duration: 0.5,
              onComplete: () => {
                cell.value = '';
                gsap.set(element, { clearProps: 'all' });
              }
            });
          }
        });
        setTimeout(resolve, 1400); // Adjusted for longer animation sequence
      });
    },

    async dropSymbols() {
      return new Promise(resolve => {
        let dropCount = 0;
        const emptyCells = this.gameGrid.filter(cell => !cell.value).length;
        
        // Process each column from bottom to top
        for (let col = 0; col < 5; col++) {
          let emptySpaces = 0;
          const drops = [];
          
          // Scan from bottom to top, tracking empty spaces
          for (let row = 4; row >= 0; row--) {
            const cell = this.gameGrid.find(c => c.row === row && c.col === col);
            
            if (!cell.value) {
              emptySpaces++;
            } else if (emptySpaces > 0) {
              // Found symbol that can drop
              drops.push({
                fromCell: cell,
                spaces: emptySpaces,
                toRow: row + emptySpaces
              });
            }
          }
          
          // Animate drops for this column
          drops.forEach(({fromCell, spaces, toRow}) => {
            const element = document.querySelector(`#content-${fromCell.row}-${fromCell.col}`);
            const targetCell = this.gameGrid.find(c => c.row === toRow && c.col === col);
            
            if (element && targetCell) {
              gsap.to(element, {
                y: spaces * 80,
                duration: 0.5,
                onComplete: () => {
                  targetCell.value = fromCell.value;
                  fromCell.value = '';
                  gsap.set(element, { clearProps: 'all' });
                  dropCount++;
                  if (dropCount === drops.length) {
                    resolve();
                  }
                }
              });
            }
          });
        }
        
        // If no drops needed, resolve immediately
        if (emptyCells === 0) {
          resolve();
        }
      });
    },

    async processLayouts() {
      const totalLayouts = this.formattedLayouts.length;
      let hasMatches = true;
      
      while (hasMatches) {
        const matches = this.findMatches();
        
        // Check if we're on second to last layout
        if (this.currentLayoutIndex + this.cascadeCount === totalLayouts - 2 && !this.tntDetonated) {
          // Handle TNT detonation if present
          const hasTNT = this.findTNTs().length > 0;
          if (hasTNT) {
            await this.triggerTNTs();
            await this.dropSymbols();
            this.tntDetonated = true;
            
            // Force load final layout after TNT handling
            this.cascadeCount++;
            await this.fillEmptySpaces();
            await this.dropSymbols(); // Add extra drop for final layout
          }
        }

        // Handle normal matches
        if (matches.length) {
          await this.pause(200);
          await this.removeMatches(matches);
          await this.dropSymbols();
          
          const nextLayout = this.formattedLayouts[this.currentLayoutIndex + this.cascadeCount + 1];
          if (nextLayout) {
            this.cascadeCount++;
            await this.fillEmptySpaces();
          } else {
            hasMatches = false;
          }
        } else {
          // If no matches found but we're at second to last layout
          if (this.currentLayoutIndex + this.cascadeCount === totalLayouts - 2 && !this.tntDetonated) {
            await this.triggerTNTs();
            // Force a pause to ensure all cells are cleared
            await this.pause(100);
            await this.dropSymbols();
            this.tntDetonated = true;
            
            // Force load final layout
            this.cascadeCount++;
            await this.fillEmptySpaces();
          }
          hasMatches = false;
        }
      }
    },

    async fillEmptySpaces() {
      return new Promise(resolve => {
        // Use cascadeCount to get next layout
        const nextLayout = this.formattedLayouts[this.currentLayoutIndex + this.cascadeCount];
        if (!nextLayout) {
          resolve();
          return;
        }

        let fillCount = 0;
        const emptyCells = this.gameGrid.filter(cell => !cell.value);

        emptyCells.forEach(cell => {
          const element = document.querySelector(`#content-${cell.row}-${cell.col}`);
          // Fill with symbol from next layout
          cell.value = nextLayout[cell.row][cell.col];
          
          if (element) {
            gsap.fromTo(element,
              { y: -80, opacity: 0, scale: 1 },
              {
                y: 0,
                opacity: 1,
                duration: 0.5,
                onComplete: () => {
                  gsap.set(element, { clearProps: 'all' });
                  fillCount++;
                  if (fillCount === emptyCells.length) resolve();
                }
              }
            );
          }
        });

        if (emptyCells.length === 0) resolve();
      });
    },

    async replayAnimation() {
      this.cascadeCount = 0;
      this.tntDetonated = false; // Reset TNT detonation flag
      gsap.set('.cell-content', { clearProps: 'all' });
      this.populateGrid();
      await this.processLayouts();
    },

    populateGrid() {
      const layout = this.formattedLayouts[this.currentLayoutIndex];
      this.gameGrid.forEach(cell => {
        cell.value = layout[cell.row][cell.col] || '';
      });
    },

    findMatches() {
      const matches = new Set();
      this.gameGrid.forEach(cell => {
        if (cell.value && cell.value !== '💣') {
          const count = this.gameGrid.filter(c => c.value === cell.value).length;
          if (count >= 5) {
            this.gameGrid.forEach(c => {
              if (c.value === cell.value) matches.add(`${c.row}-${c.col}`);
            });
          }
        }
      });
      return Array.from(matches);
    },

    findTNTs() {
      const TNTs = [];
      this.gameGrid.forEach(cell => {
        if (cell.value === '💣') {
          TNTs.push(cell);
        }
      });
      return TNTs;
    },

    getAffectedCells(TNT) {
      const affected = new Set();
      
      // Check 3x3 grid around TNT
      for (let row = TNT.row - 1; row <= TNT.row + 1; row++) {
        for (let col = TNT.col - 1; col <= TNT.col + 1; col++) {
          // Skip if outside grid
          if (row >= 0 && row < 5 && col >= 0 && col < 5) {
            affected.add(`${row}-${col}`);
          }
        }
      }
      return Array.from(affected);
    },

    async triggerTNTs() {
      return new Promise((resolve, reject) => {
        const TNTs = this.findTNTs();
        if (!TNTs.length) {
          resolve();
          return;
        }

        const allAffectedCells = new Set();
        TNTs.forEach(TNT => {
          const affected = this.getAffectedCells(TNT);
          affected.forEach(cell => allAffectedCells.add(cell));
        });

        // Create main timeline
        const timeline = gsap.timeline();
        
        // 1. Highlight TNTs
        TNTs.forEach(TNT => {
          const element = document.querySelector(`#content-${TNT.row}-${TNT.col}`);
          if (element) {
            timeline.to(element, {
              scale: 1.2,
              duration: 0.3,
              ease: "bounce.out"
            });
          }
        });

        // 2. Detonate TNTs
        timeline.to(TNTs.map(TNT => 
          document.querySelector(`#content-${TNT.row}-${TNT.col}`)
        ), {
          scale: 2,
          opacity: 0,
          duration: 0.5
        }).then(() => {
          // 3. Remove affected cells
          Promise.all(
            Array.from(allAffectedCells).map(pos => {
              return new Promise(resolveCell => {
                const [row, col] = pos.split('-');
                const cell = this.gameGrid.find(c => c.row === parseInt(row) && c.col === parseInt(col));
                const element = document.querySelector(`#content-${row}-${col}`);
                
                if (element && cell) {
                  gsap.to(element, {
                    scale: 0,
                    opacity: 0,
                    duration: 0.3,
                    onComplete: () => {
                      cell.value = '';
                      gsap.set(element, { clearProps: 'all' });
                      resolveCell();
                    }
                  });
                } else {
                  resolveCell();
                }
              });
            })
          ).then(() => {
            setTimeout(resolve, 300);
          }).catch(reject);
        });
      });
    },

    pause(duration) {
      return new Promise(resolve => setTimeout(resolve, duration));
    },

    async startAnimation() {
      this.animationStarted = true;
      await this.processLayouts();
    }
  },
  mounted() {
    this.animationStarted = false;
    if (this.formattedLayouts.length) {
      this.populateGrid();
      this.isDataReady = true;
    }
  }
};
</script>

<style>
.grid-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.grid {
  display: grid;
  grid-template-columns: repeat(v-bind(cols), 80px);
  grid-template-rows: repeat(v-bind(rows), 80px);
  gap: 2px;
}

.grid-cell {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
}

.cell-content {
  font-size: 75px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}
</style>